<template>
  <el-select
    v-if="isFieldAvailableForEdit(scope.row, 'scale_type')"
    v-model="scope.row.scale_type"
    size="mini"
    placeholder="Выберите шкалу"
    @change="saveElementPropChange(scope.row, 'scale_type')"
  >
    <el-option
      v-for="(scale, scaleType) in $companyConfiguration.scales.getScales()"
      :key="scaleType"
      :label="scale.showName"
      :value="scaleType"
    >
    </el-option>
  </el-select>
  <div v-else>
    {{
      scope.row.scale_type
        ? $companyConfiguration.scales.getScale(scope.row.scale_type)['showName']
        : ''
    }}
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
export default {
  name: 'element-scale-table-column',
  mixins: [ElementPropTableColumn],
  components: {ClickToEdit},

  props: {},
  data() {
    return {

    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style>

</style>
